import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkBrandExists } from '../../configurations/Brands';
import NotFound from '../dynamicPages/NotFound';

function RouteOne({ match: { params: { brand } } }) {
  const normalizedBrand = brand.toLowerCase();

  if (checkBrandExists(normalizedBrand)) {
    const targetBrand = normalizedBrand === 'directpay' ? 'partners' : normalizedBrand;
    return <Redirect to={`/${targetBrand}/en/categories`} />;
  }

  return <NotFound />;
}

export default RouteOne;
