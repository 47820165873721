/* eslint-disable react/no-unstable-nested-components */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { translate } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import { baseUrl, devConsoleLog } from './config';
import { getSqlBrand } from '../configurations/Brands';
import { logAmpEvent, event } from './Amplitude';

function isThisTheSameBrand(page, server) {
  /* Convert server name to local page name */
  if (page === null || server === undefined) {
    return true;
  }
  const converted = getSqlBrand(page);
  return converted === server;
}

/**
 * Checks if any article has length greater than 0
 * @param {Object} res Result DF from list category API
 * @returns {boolean} True if article length > 0. False if else.
 */
function moreThanOneArticle(res) {
  try {
    // DO NOT CHANGE THIS. Code return true IF any article > 0
    return res.Sections.some((section) => (section.Articles?.length > 0));
  } catch (e) {
    devConsoleLog(e);
    return false;
  }
}

// eslint-disable-next-line import/prefer-default-export
export function DbConnect(Layout, pageType) {
  function Categories(props) {
    const { brand, lang } = useSelector((state) => state);
    // const sql = getSqlBrand(brand);
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [error, setError] = useState(false);
    const [categories, setCategories] = useState(null);
    const url = `${baseUrl()}/${lang}/categories/all/${brand}`;

    useEffect(() => {
      let isCancelled = false;
      setCategories(null);
      setShouldUpdate(true);
      axios.get(url, { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
        .then((res) => {
          if (isCancelled) return;
          setCategories(res.data);
          setShouldUpdate(false);
        })
        .catch((err) => {
          if (isCancelled) return;
          Sentry.captureException(err);
          devConsoleLog(err);
          setError(true);
        });
      return () => {
        isCancelled = true;
      };
    }, [lang, brand]);

    return <Layout categories={categories} loading={shouldUpdate} error={error} {...props} />;
  }

  function Section(props) {
    const { id } = props;
    const { brand, lang } = useSelector((state) => state);
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [sections, setSections] = useState(null);
    const [categories, setCategories] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(false);
    const [partnerCategoryRedirect, setPartnerCategoryRedirect] = useState(false);
    const [paymentCategoryRedirect, setPaymentCategoryRedirect] = useState(false);
    const url = `${baseUrl()}/${lang}/sections/${id}`;
    useEffect(() => {
      setShouldUpdate(true);
      axios
        .get(url, { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
        .then((res) => {
          // force redirect for Phoenix rebranding https://3.basecamp.com/3425901/buckets/21398009/todos/8076827997
          switch (res.data?.categoryID) {
            case 163: // Customer FAQ
              setPaymentCategoryRedirect(true);
              return;
            case 161: // Partner FAQ
            case 443: // Shopify FAQ
            case 403: // Xero FAQ
              setPartnerCategoryRedirect(true);
              return;
            default:
              break;
          }
          if (res === '' || res.data === '') {
            setNotFound(true);
          } else if (
            res.data.Articles.length !== 0 &&
            isThisTheSameBrand(brand, res.data.Articles[0].Brand)) {
            const url2 = `${baseUrl()}/${lang}/categories/${res.data.categoryID}`;
            axios.get(url2, { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
              .then((res2) => {
                setCategories(res2.data);
                setSections(res.data);
                setShouldUpdate(false);
              })
              .catch((err2) => {
                logAmpEvent(event.DB_ERROR, { Reason: err2.message });
                Sentry.captureException(err2);
                devConsoleLog(err2);
                setError(true);
              });
          } else {
            setNotFound(true);
          }
        })
        .catch((err) => {
          logAmpEvent(event.DB_ERROR, { Reason: err.message });
          Sentry.captureException(err);
          devConsoleLog(err);
          setError(true);
        });
    }, [lang, id]);
    return (
      <Layout
        error={error}
        loading={shouldUpdate}
        sections={sections}
        notFound={notFound}
        {...props}
        categories={categories}
        partnerCategoryRedirect={partnerCategoryRedirect}
        paymentCategoryRedirect={paymentCategoryRedirect}
      />
    );
  }

  function ListCategoryAndSection(props) {
    const { id, brand } = props;
    const { lang } = useSelector((state) => state);
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(false);
    const [sections, setSections] = useState(null);
    const [paymentCategoryRedirect, setPaymentCategoryRedirect] = useState(false);
    const [partnerCategoryRedirect, setPartnerCategoryRedirect] = useState(false);
    const url = `${baseUrl()}/${lang}/categories/${id}`;

    useEffect(() => {
      let isCancelled = false;
      setShouldUpdate(true);
      axios
        .get(url, { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
        .then((res) => {
          if (isCancelled) return;
          // eslint-disable-next-line no-param-reassign
          res = res.data;
          switch (res.CategoryID) {
            case 163: // Customer FAQ
              setPaymentCategoryRedirect(true);
              return;
            case 161: // Partner FAQ
            case 443: // Shopify FAQ
            case 403: // Xero FAQ
              setPartnerCategoryRedirect(true);
              return;
            default:
              break;
          }
          if (!isThisTheSameBrand(brand, res.Brand)) {
            setNotFound(true);
          } else if (res.CategoryID === -1) { // Category Not found
            setNotFound(true);
            setShouldUpdate(false);
          } else if (moreThanOneArticle(res)) {
            res.Sections.forEach((item, index, object) => {
              if (!('Title' in item)) {
                object.splice(index, 1);
              }
            });
            setSections(res);
            setShouldUpdate(false);
          } else {
            setNotFound(true);
          }
        })
        .catch((err) => {
          if (isCancelled) return;
          logAmpEvent(event.DB_ERROR, { Reason: err.message });
          Sentry.captureException(err);
          devConsoleLog(err, 'dbc3');
          setError(true);
        });
      return () => {
        isCancelled = true;
      };
    }, [lang, id]);
    return (
      <Layout
        error={error}
        loading={shouldUpdate}
        sections={sections}
        notFound={notFound}
        {...props}
        partnerCategoryRedirect={partnerCategoryRedirect}
        paymentCategoryRedirect={paymentCategoryRedirect}
      />
    );
  }

  function Articles(props) {
    const { id } = props;
    const { brand, lang } = useSelector((state) => state);
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [article, setArticle] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(false);
    const url = `${baseUrl()}/${lang}/articles/${id}`;
    useEffect(() => {
      setShouldUpdate(true);
      axios
        .get(url, { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
        .then((res) => {
          // don't have to check widget since it should never hit
          if (res.data === '') {
            setNotFound(true);
            setShouldUpdate(false);
          }
          if (isThisTheSameBrand(brand, res.data.Brand)) {
            setArticle(res.data);
            setShouldUpdate(false);
          } else {
            setNotFound(true);
          }
          setShouldUpdate(false);
        })
        .catch((err) => {
          logAmpEvent(event.DB_ERROR, { Reason: err.message });
          Sentry.captureException(err);
          devConsoleLog(err, 'dbc article');
          setError(true);
        });
    }, [lang, id]);
    return (
      <Layout
        article={article}
        notFound={notFound}
        error={error}
        loading={shouldUpdate}
        {...props}
      />
    );
  }
  switch (pageType) {
    case 'categories':
      return translate(Categories);
    case 'section':
      return translate(Section);
    case 'listCategoryAndSection':
      return translate(ListCategoryAndSection);
    case 'article':
      return translate(Articles);
    default:
      return <Layout />;
  }
}
