import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import './contactusform.scss';
import './ContactUsPaySimply.scss';
import { useSelector } from 'react-redux';
import { SubmitContactUs } from '../../../helperFunctions/SubmitContactUs';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsPartners({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);
  const emailRef = useRef();
  const bodyRef = useRef();
  const businessRef = useRef();
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [product, setProduct] = useState('');

  const [validateEmailOnChange, setValidateEmailOnChange] = useState(false);
  const [validateDescriptionOnChange, setValidateDescriptionOnChange] = useState(false);
  const [validateBusinessOnChange, setValidateBusinessOnChange] = useState(false);
  const [validateProductOnChange, setValidateProductOnChange] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  const [formError, setFormError] = useState({
    customerEmail: null,
    businessName: null,
    description: null,
    product: null,
  });

  function sendAPI() {
    const data = {
      BrandID: 'Partners',
      BusinessName: businessRef.current.value,
      Product: product,
      CustomerEmail: emailRef.current.value,
      Body: bodyRef.current.value,
      Language: lang,
    };

    const sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShowError(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShowError(true);
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function validateEmail(value) {
    if (value.length > 50) return t('Validation_Length_Max').replace('xMax', '50');
    if (!/^[\w!#$%&*+/=?`{|}~^-‘’']+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/.test(value)) return t('Validation_Type_Email');
    return null;
  }

  function validateEmpty(value) {
    if (value.trim().length === 0) return t('Validation_Required');
    return null;
  }

  function validateMaxCharacter(value, characterLimit) {
    if (value.trim().length > characterLimit) return t('ContactForm_Max_50');
    return null;
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  function validateFormFields(force) {
    const errors = {
      customerEmail: null,
      businessName: null,
      description: null,
      product: null,
    };
    if ((validateEmailOnChange || force) && emailRef.current) {
      const { value } = emailRef.current;
      errors.customerEmail = validateEmpty(value) ||
      validateEmail(value) ||
      validateMaxCharacter(value, 100);
    }

    if ((validateBusinessOnChange || force) && businessRef.current) {
      const { value } = businessRef.current;
      errors.businessName = validateEmpty(value) || validateMaxCharacter(value, 50);
    }

    if ((validateDescriptionOnChange || force) && bodyRef.current) {
      const { value } = bodyRef.current;
      errors.description = validateEmpty(value) || validateMaxCharacter(value, 1500);
    }

    if (validateProductOnChange || force) {
      errors.product = ((product === '') ? t('ContactForm_Payment_Option_Validation_Error') : null);
    }

    setFormError(errors);
    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setValidateDescriptionOnChange(true);
    setValidateEmailOnChange(true);
    setValidateBusinessOnChange(true);
    setValidateProductOnChange(true);

    const errors = validateFormFields(true);
    if (!Object.values(errors).some((err) => err !== null)) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
  }, []);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  useEffect(() => {
    validateFormFields();
  }, [
    lang,
    validateBusinessOnChange,
    validateEmailOnChange,
    validateDescriptionOnChange,
    validateProductOnChange,
  ]);

  return (
    <>
      {showError && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShowError(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/${brand}/${lang}${isMobile}` : `/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">{t('ContactForm_Fields_Required')}</p>
          <Form noValidate onSubmit={handleSubmit}>

            {/* Email */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <Form.Label as="legend" id="aria-email" className="form-labels">
                {t('ContactForm_General_Email_Label')}
              </Form.Label>
              <Form.Text className="muted email-help">
                {t('ContactForm_General_Email_Help')}
              </Form.Text>
              <Form.Control
                aria-labelledby="aria-email aria-email-warn"
                ref={emailRef}
                className={formError.customerEmail ? 'is-invalid' : ''}
                onBlur={() => setValidateEmailOnChange(true)}
                onChange={() => {
                  if (validateEmailOnChange) validateFormFields();
                }}
              />
              <Form.Text className="errorMessage">{formError.customerEmail}</Form.Text>
            </Form.Group>

            {/* Business Name */}
            <Form.Group className="form-groups" required>
              <Form.Label as="legend" id="aria-desc" className="form-labels">
                {t('ContactForm_Business_Name')}
              </Form.Label>
              <Form.Control
                aria-labelledby="aria-desc"
                ref={businessRef}
                className={formError.businessName ? 'is-invalid' : ''}
                onBlur={() => setValidateBusinessOnChange(true)}
                onChange={() => {
                  if (validateBusinessOnChange) validateFormFields();
                }}
              />
              <Form.Text className="errorMessage">{formError.businessName}</Form.Text>
            </Form.Group>

            {/* Dropdown: Product */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_Product_"
            >
              <fieldset>
                <Form.Label id="option_label" as="legend" className="form-labels">
                  {t('ContactForm_Product_Title')}
                </Form.Label>

                {/* Dropdown: Product */}
                <select
                  className={`custom-select ${formError.product ? 'is-invalid' : ''}`}
                  id="product"
                  onBlur={() => setValidateProductOnChange(true)}
                  onChange={() => {
                    const currentVal = document.getElementById('product').value;
                    setProduct(currentVal);
                    if (validateProductOnChange) validateFormFields();
                  }}
                >
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <option defaultValue="" />
                  <option value="PartnerPay / Interac e-Transfer">{t('ContactForm_PaymentOption_PartnerPay')}</option>
                  <option value="PaySimply">PaySimply</option>
                  <option value="Pay in person at Canada Post">{t('ContactForm_PaymentOption_InPerson')}</option>
                </select>
              </fieldset>

              <Form.Text className="errorMessage">
                {formError.product}
              </Form.Text>
            </Form.Group>

            {/* How can we help */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Body_"
              required
            >
              <fieldset>
                <Form.Label as="legend" id="aria-desc" className="form-labels">
                  {t('ContactForm_General_Body_Label')}
                </Form.Label>

                <Form.Control
                  aria-labelledby="aria-desc"
                  as="textarea"
                  rows={8}
                  ref={bodyRef}
                  className={formError.description ? 'is-invalid' : ''}
                  onBlur={() => setValidateDescriptionOnChange(true)}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (validateDescriptionOnChange) validateFormFields();
                  }}
                />
                <Form.Text className="errorMessage">{formError.description}</Form.Text>
              </fieldset>
            </Form.Group>
            <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />
            <Button
              id="submit-button"
              className="paysimply-button"
              type="submit"
              disabled={disable}
              size="lg"
              style={{ backgroundColor: '#2B4E7B' }}
            >
              {t('ContactForm_Submit_button')}
            </Button>

          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsPartners);
