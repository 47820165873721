import React from 'react';
import { Link } from 'react-router-dom';
import { setLanguage, t, translate } from 'react-switch-lang';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetLanguage } from '../../redux/Action';
import './NavBar.scss';
import { brandConfig as allBrands, getFontColor, getLogoDisplayText, getLogoDisplayTextColor, languageText } from '../../configurations/Brands';
import { replaceLinkLanguage } from '../../helperFunctions/LinkGenerator';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import usingIE from '../../helperFunctions/UsingIE';

function Navbar() {
  const { brand, lang } = useSelector((state) => state);
  const dispatch = useDispatch();

  const curLang = lang;
  const brandConfig = allBrands[brand];
  const link = brand !== 'nowprepay' ? t(brandConfig.text.navBar.link) : `/nowprepay/${lang}/apps`;

  let ieStyle;
  if (usingIE()) {
    ieStyle = {
      padding: '10px',
      paddingLeft: '5px',
      marginTop: '-20px',
    };
  }

  function handleLanguageSwitch(newLang) {
    setLanguage(newLang);
    document.documentElement.setAttribute('lang', newLang);
    dispatch(actionSetLanguage(newLang));
    logAmpEvent(event.USER_CLICKED_NAVBAR_SWITCH_LANGUAGE);
  }

  const languageDropDown = () => {
    if (allBrands[brand].languages.length > 1) {
      return (
        <li className="nav-item dropdown" style={ieStyle}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="cust-nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ color: brandConfig.theme.fontColor }}
          >
            {t('Navbar_Language')}
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {/* eslint-disable-next-line no-shadow */}
            {brandConfig.languages.map((brandLang) => (
              <Link
                key={brandLang}
                to={replaceLinkLanguage(brandLang)}
                lang={brandLang}
                onClick={() => {
                  handleLanguageSwitch(brandLang);
                }}
                className={`language-dropdown-item${
                  brandLang === curLang ? ' selectedLanguage' : ''
                }`}
              >
                <span className="black-font">
                  {languageText[brandLang]}
                </span>
              </Link>
            ))}
          </div>
        </li>
      );
    }
    return '';
  };

  const contactUsLink = () => ((brand !== 'canadapost-singleloadprepaid') ? (
    <li className="nav-item" style={ieStyle}>
      <Link
        className="nav-item cust-contact-btn"
        to={`/${brand}/${curLang}/contactus`}
        style={{ color: getFontColor(brand) }}
        onClick={() => {
          logAmpEvent(event.USER_CLICKED_NAVBAR_CONTACT_US);
        }}
      >
        <span style={{ color: getFontColor(brand) }}>
          {t('General_Contact_Us')}
        </span>
      </Link>
    </li>
  ) :
    null);

  const paySimplyCheckPaymentLink = () => (
    (brand === 'paysimply') && (
      <li className="nav-item" style={ieStyle}>
        <a
          href={t('Link_CheckStatusWithUTM')}
          aria-label={t('Navbar_Paysimply_CheckPaymentStatus')}
          style={{ color: getFontColor(brand) }}
        >
          {t('Navbar_Paysimply_CheckPaymentStatus')}
        </a>
      </li>
    )
  );

  // Returns a link for dropdown and nav bar if brandConfig[brand].text.navbar.text exists
  const brandDynamicLink = () => {
    if (brandConfig.text.navBar.text === '') return '';
    return (
      <li className="nav-item" style={ieStyle}>
        <a
          className="cust-nav-link"
          style={{ color: getFontColor(brand) }}
          href={link}
          target={(brand === 'nowprepay') ? '' : '_blank'}
          rel="noreferrer"
        >
          {t(brandConfig.text.navBar.text)}
        </a>
      </li>
    );
  };

  return (
    <>
      <div id="top-of-page" />
      {!brandConfig ? (
        // default NavBar
        <nav
          className="navbar navbar-expand-lg"
          style={{
            backgroundColor: '#232222',
            height: 40,
          }}
        />
      ) : (
        <nav
          className={`navbar navbar-expand-lg ${brand} ${brandConfig.theme.navclass}`}
          style={{ backgroundColor: brandConfig.theme.bkgColor }}
        >

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => logAmpEvent(event.USER_CLICKED_HAMBURGER)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Link
            id="navbar-branding"
            to={`/${brand}/${curLang}/categories`}
            aria-label={brandConfig.theme.logoAltText}
            onClick={() => logAmpEvent(event.USER_CLICKED_NAVBAR_HOME_ICON)}
          >
            <img
              id="navbar-logo"
              src={brandConfig.theme.logo}
              alt={brandConfig.theme.logoAltText}
            />
            <span id="navbarLogoText" style={{ color: getLogoDisplayTextColor(brand) }}>{getLogoDisplayText(brand)}</span>
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav ${brandConfig.theme.langnumber}`}>
              {brandDynamicLink()}
              {paySimplyCheckPaymentLink()}
              {languageDropDown()}
              {contactUsLink()}
            </ul>
          </div>
        </nav>
      )}
    </>
  );
}

export default translate(Navbar);
