import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { t } from 'react-switch-lang';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import Search from '../../components/algolia/AlgoliaSearch';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import './Articles.scss';
import { brandTitleGet } from '../../configurations/Brands';
import { logAmpEvent, event } from '../../helperFunctions/Amplitude';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import LoadingSpinner from '../../components/widget/widgetHelper/LoadingSpinner';
import { actionSetBrand } from '../../redux/Action';

function Articles(props) {
  const { article, notFound, error, loading, id } = props;
  const { brand, lang } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    logAmpEvent(event.USER_VIEWED_ARTICLE, { 'Article ID': id });
  }, []);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  useEffect(() => {
  // Redirect if article.Body only contains an integer
    if (!loading && article?.Body && /^-?\d+$/.test(article.Body)) {
      if (article.CategoryID === 163) { // DirectPay Customer FAQ category
        dispatch(actionSetBrand('payments'));
        history.replace(`/payments/${lang}/articles/${article.Body}`);
      } else if (brand === 'directpay') {
        dispatch(actionSetBrand('partners'));
        history.replace(`/partners/${lang}/articles/${article.Body}`);
      } else {
        history.replace(`/${brand}/${lang}/articles/${article.Body}`);
      }
    }
  }, [loading, article, brand, lang, history]);

  if (notFound) {
    return <Redirect to={`/${brand}/${lang}/notFound`} />;
  }

  if (error) {
    return <Redirect to={`/${brand}/${lang}/error`} />;
  }

  if (loading) return <LoadingSpinner />;

  DocumentTitle(
    t('PageTitle_Dynamic_Title')
      .replace('xBrand', brandTitleGet(brand, lang))
      .replace('xName', article.Title)
  );
  return (
    <div>
      <div className="flex-box">
        <div className="flex-crumbs">
          <Breadcrumb
            sectionID={article.SectionID}
            sectionName={article.Section}
            categoryID={article.CategoryID}
            categoryName={article.Category}
            lang={lang}
            lastActive
            articleStatus={article.Status}
          />
        </div>

        <div id="search-content" className="flex-search">
          <Search mini />
        </div>
      </div>
      <LayoutStyle id={id} article={article} brand={brand} lang={lang} />
    </div>
  );
}

const localeZone = {
  en: 'en-CD',
  fr: 'fr',
};

function formatDate(value) {
  const { lang } = useSelector((state) => state);

  const date = new Date(value);
  let currentLocale = 'en-CD';
  if (localeZone[lang] !== undefined) {
    currentLocale = localeZone[lang];
  }
  return Intl.DateTimeFormat(currentLocale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date);
}

function CustomTable({ children }) {
  return <Table className="table-bordered">{children}</Table>;
}
function Customhead({ children }) {
  return <th scope="col">{children}</th>;
}

function CustomLink(props) {
  const { lang } = useSelector((state) => state);
  const { href, children, title } = props;

  if (
    href.includes('localhost') ||
    href.includes('help.paymentsource.co') ||
    href.includes('help.paymentsource.net')
  ) {
    let path = href.replace(/^.*\/\/[^/]+/, '');
    const files = path.split('/');
    if (files.length >= 3) {
      path = `/${files[1]}/${lang}`;
      for (let i = 3; i < files.length; i += 1) {
        path += `/${files[i]}`;
      }
    }

    return (
      <a href={path} rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  const cleanedHref = href.replace(/\*/g, '');

  return (title) ? (
    <a href={cleanedHref} rel="noreferrer" target="_blank" aria-label={title.replaceAll(/\*/g, '')}>
      {children}
    </a>
  ) : (
    <a href={cleanedHref} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
}

function CustomImg(props) {
  const { alt, src } = props;

  function altTag() {
    const re = /^[^#]*/;
    return alt.match(re);
  }

  function findHeight(text) {
    return (text.includes('#height'));
  }

  function findWidth(text) {
    return (text.includes('#width'));
  }

  const cleanedSrc = src.replace(/\*/g, '');

  const first = alt.split(' ');
  const htIdx = first.findIndex(findHeight);
  const wtIdx = first.findIndex(findWidth);

  return (
    <img
      src={cleanedSrc}
      alt={altTag()}
      height={(htIdx !== -1) ? first[htIdx].replace('#height:', '') : '100%'}
      width={(wtIdx !== -1) ? first[wtIdx].replace('#width:', '') : '100%'}
    />
  );
}

function LayoutStyle({ article: { UpdatedDate, Title, Body } }) {
  return (
    <div id="article-layout">
      <div id="skip-link-content">
        <h1 id="article-title" aria-label="article title">
          <ReplaceTextWithElement
            text={Title}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </h1>
      </div>

      <div id="article-content" className="div-center">
        <ReactMarkdown
          remarkPlugins={[gfm]}
        /* eslint-disable-next-line react/no-children-prop */
          children={Body
            .replaceAll(/(\r\n|\n)/g, '  \n')
            .replaceAll(/(\[)(Interac)(\])/g, '$1*$2*$3')
            .replaceAll(/(^|\s)(Interac)(\s|$)/g, '$1*$2*$3')
            .replaceAll(/(^|\s)(Interac)(ᴹᴰ)(\s|$)/g, '$1*$2*$3$4')
            .replaceAll(/(\*{1})(Interac)(\*{1})(ᴹᴰ)/g, '*$2*$4') // Handles one star (ensures it remains surrounded by one star)
            .replaceAll(/(\*{2,})(Interac)(\*{2,})(ᴹᴰ)?/g, '***$2***$4')}
          components={{
            table: CustomTable,
            th: Customhead,
            a: CustomLink,
            link: CustomLink,
            img: CustomImg,
          }}
        />
      </div>
      <div id="bottom-stuff">
        <div id="last-update-text">
          {t('Article_Last_Updated_On')}
          {' '}
          {formatDate(UpdatedDate)}
        </div>
      </div>
    </div>
  );
}

export default DbConnect(Articles, 'article');
