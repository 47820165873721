import React, { useEffect } from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import { t, translate } from 'react-switch-lang';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { faCashRegister, faFileInvoiceDollar, faGiftCard, faLaptop, faMobile, faReceipt, faUserChart } from '@awesome.me/kit-299dd46f46/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { isDevelopment, isRC } from '../../helperFunctions/config';
import './NowPrepayApp.scss';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';

function AppLinkListItem(appName) {
  return (
    <li><a className="appLinkListItem" href={t(`Nowprepay_Q_Apps_PinCreditLink_${appName}`)} aria-label={t(`LinkDescription_PinCreditHelp_${appName}`)}>{t(`Nowprepay_Q_Apps_PinCreditLinkText_${appName}`)}</a></li>
  );
}

function NowPrepayApp() {
  DocumentTitle(t('DocTitle_NowPrepayQuickLink'));
  const { lang } = useSelector((state) => state);
  function getMyEcashLink() {
    const specificUrl = `nowprepay/${lang}/myecash`;
    if (isDevelopment()) {
      const splitUrl = window.location.href.split('/');
      return `http://${splitUrl[2]}/${specificUrl}`;
    } if (isRC()) {
      return `https://help.paymentsource.co/${specificUrl}`;
    }
    return t('Link_MyeCash', null, lang);
  }

  useEffect(() => {
    logAmpEvent(event.USER_VIEWED_QUICK_LINK);
  }, []);

  return (
    <div className="nowprepay-app-grid px-3" id="skip-link-content">
      <CardDeck className="np-card-group orange-group">
        <Card className="card-container highlighted">
          <div className="np-card-text">
            <Card.Title className="orange-title-container">
              <FontAwesomeIcon icon={faReceipt} className="text-primary orange" size="3x" />
              <h3 className="quick-app-card-title">
                {t('Nowprepay_Q_Apps_PinCreditTitle')}
              </h3>
            </Card.Title>
            <Card.Body className="np-card-body">
              <p>{t('Nowprepay_Q_Apps_PinCreditSubtitle1')}</p>
              <p className="mb-0">{t('Nowprepay_Q_Apps_PinCreditSubtitle2')}</p>
              <ul>
                {AppLinkListItem('VerifonePOS')}
                {AppLinkListItem('AndroidPOS')}
                {AppLinkListItem('WebStore')}
              </ul>
            </Card.Body>
          </div>
        </Card>

        <Card className="card-container highlighted">
          <div className="np-card-text">
            <Card.Title className="orange-title-container">
              <FontAwesomeIcon icon={faGiftCard} size="3x" className="text-primary orange" />
              <h3 className="quick-app-card-title newItem">
                {t('Nowprepay_Q_Apps_GiftCardOrdersTitle')}
                <span className="newBadge">{t('NewTxt')}</span>
              </h3>
            </Card.Title>
            <Card.Body className="np-card-body">
              <p>{t('Nowprepay_Q_Apps_GiftCardOrdersSubtitle')}</p>
              <p><a className="appLinkListItem" href={t('Nowprepay_Q_Apps_GiftCardOrdersLink')} aria-label={t('LinkDescription_GiftCardOrdersHelp')}>{t('Nowprepay_Q_Apps_GiftCardOrdersLink_Text')}</a></p>
            </Card.Body>
          </div>
        </Card>
      </CardDeck>
      <CardDeck className="np-card-group orange-group">
        <Card className="card-container">
          <div className="np-card-text">
            <Card.Title className="orange-title-container">

              <FontAwesomeIcon icon={faUserChart} size="3x" className="text-primary orange" />

              <h3 className="quick-app-card-title">
                {t('Nowprepay_Q_Apps_MSCardTitle')}
              </h3>
            </Card.Title>
            <Card.Body className="np-card-body">
              <div className="ul-div-wrapper">
                <ul className="point-ul">
                  <li>{t('Nowprepay_Q_Apps_MSCardPoint1')}</li>
                  <li>{t('Nowprepay_Q_Apps_MSCardPoint3')}</li>
                  <li>{t('Nowprepay_Q_Apps_MSCardPoint2')}</li>
                  <li>{t('Nowprepay_Q_Apps_MSCardPoint4')}</li>
                </ul>
              </div>
            </Card.Body>
          </div>
          <a
            href={t('Link_eMS')}
            className="RetailerCentral"
            onClick={() => {
              logAmpEvent(event.USER_OPEN_APP, { AppName: 'Retailer Central' });
            }}
            title={t('Nowprepay_Q_Apps_Login_Retailer_Central')}
          >
            <div
              className="card-action orange-button"
              id="RetailerCentralBtn"
            >
              {t('Nowprepay_Q_Apps_MSCardLaunchBtn')}
            </div>
          </a>
        </Card>

        <Card className="card-container">
          <div className="np-card-text">
            <Card.Title className="orange-title-container">

              <FontAwesomeIcon icon={faCashRegister} size="3x" className="text-primary orange" />

              <h3 className="quick-app-card-title">
                {t('Nowprepay_Q_Apps_WebStoreCardTitle')}
              </h3>
            </Card.Title>
            <Card.Body className="np-card-body">
              <ul className="point-ul">
                <li>{t('Nowprepay_Q_Apps_WebStoreCardPoint1')}</li>
                <li>{t('Nowprepay_Q_Apps_WebStoreCardPoint3')}</li>
                <li>{t('Nowprepay_Q_Apps_WebStoreCardPoint2')}</li>
              </ul>

            </Card.Body>
          </div>
          <a
            href={t('Link_WebStore')}
            className="appLink webStore"
            onClick={() => {
              logAmpEvent(event.USER_OPEN_APP, { AppName: 'Web Store' });
            }}
            title={t('Nowprepay_Q_Apps_Login_WebStore')}
          >
            <div
              className="card-action orange-button"
              id="webStoreBtn"
            >
              {t('Nowprepay_Q_Apps_WebStoreCardLaunchBtn')}
            </div>
          </a>
        </Card>

      </CardDeck>
      <h2
        className="lbl-ecash-merchant"
      >
        {' '}
        {t('Nowprepay_Q_Apps_For_eCashMerchants')}
        {' '}

      </h2>

      <CardDeck className="np-card-group green-group">

        {/* MY ECASH WEB */}
        <Card className="card-container">
          <div className="np-card-text">
            <Card.Title className="green-title-container">
              <FontAwesomeIcon icon={faLaptop} size="3x" className="text-primary green" />
              <div className="green-title-text-container">
                <h3 className="quick-app-card-title ">
                  {t('Nowprepay_Q_Apps_eCashWebCardTitle')}
                </h3>
                <span className="device-type">
                  {t('Nowprepay_Q_Apps_eCashWebCardPoint1')}
                </span>
              </div>
            </Card.Title>
            <Card.Body className="np-card-body">
              <ul className="point-ul">
                <li>{t('Nowprepay_Q_Apps_eCashWebCardPoint2')}</li>
                <li>{t('Nowprepay_Q_Apps_eCashWebCardPoint4')}</li>
                <li>{t('Nowprepay_Q_Apps_eCashWebCardPoint3')}</li>
              </ul>
            </Card.Body>
          </div>
          <a
            href={t('Link_eCashWeb')}
            className="appLink eCashWeb"
            onClick={() => {
              logAmpEvent(event.USER_OPEN_APP, { AppName: 'eCash Web' });
            }}
            title={t('Nowprepay_Q_Apps_Login_Ecash_Web')}
          >
            <div
              className="card-action green-button"
              id="eCashWebBtn"
            >
              {t('Nowprepay_Q_Apps_eCashWebCardLaunchBtn')}
            </div>
          </a>
        </Card>

        {/* MY ECASH CARD */}
        <Card className="card-container">
          <div className="np-card-text">
            <Card.Title className="green-title-container">
              <FontAwesomeIcon icon={faMobile} size="3x" className="text-primary green" />
              <div className="green-title-text-container">
                <h3 className="quick-app-card-title ">
                  {t('Nowprepay_Q_Apps_MyeCashCardTitle')}
                </h3>
                <span className="device-type">
                  {t('Nowprepay_Q_Apps_MyeCashCardPoint1')}
                </span>
              </div>
            </Card.Title>
            <Card.Body className="np-card-body">
              <ul className="point-ul">
                <li>{t('Nowprepay_Q_Apps_MyeCashCardPoint2')}</li>
                <li>{t('Nowprepay_Q_Apps_MyeCashCardPoint3')}</li>
                <li>{t('Nowprepay_Q_Apps_MyeCashCardPoint4')}</li>
                <li>{t('Nowprepay_Q_Apps_MyeCashCardPoint5')}</li>
              </ul>

            </Card.Body>
          </div>
          <a
            href={getMyEcashLink()}
            onClick={() => {
              logAmpEvent(event.USER_OPEN_APP, { AppName: 'My eCash' });
            }}
            title={t('Nowprepay_Q_Apps_Learn_More_Ecash')}
          >
            <div
              className="card-action green-button"
            >
              {t('Nowprepay_Q_Apps_MyeCashCardLearnMoreBtn')}
            </div>
          </a>
        </Card>

      </CardDeck>

      {/* Paysimply */}
      <CardDeck id="card-deck-paysimply">
        <Card className="card-container-paysimply">
          <div className="np-card-text">
            <Card.Title className="green-title-container">
              <FontAwesomeIcon icon={faFileInvoiceDollar} size="3x" className="text-primary red" />
              <div className="green-title-text-container">
                <h3 className="quick-app-card-title">
                  {t('Nowprepay_Q_Apps_PaySimply_Title')}
                </h3>
              </div>
            </Card.Title>
            <Card.Body className="np-card-body m-0 mb-1 p-0 pr-3">
              <div>
                <ReplaceTextWithElement
                  text={t('Nowprepay_Q_Apps_PaySimply_Body1')}
                  replaceWith={<i>Interac</i>}
                  replaceKey="Interac"
                />
              </div>
              <div>{t('Nowprepay_Q_Apps_PaySimply_Body2')}</div>
            </Card.Body>
          </div>
          <a
            href={t('Link_PaySimply_UTM_NPPQuickLink')}
            onClick={() => {
              logAmpEvent(event.USER_OPEN_APP, { AppName: 'PaySimply' });
            }}
            title={t('Nowprepay_Q_Apps_PaySimply_Link_Text')}
          >
            <div className="card-action red-button">
              {t('Nowprepay_Q_Apps_PaySimply_Link_Text')}
            </div>
          </a>
        </Card>
      </CardDeck>

    </div>
  );
}

export default translate(NowPrepayApp);
