import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  connectHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import { t } from 'react-switch-lang';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-unresolved
import { faChevronRight } from '@awesome.me/kit-299dd46f46/icons/classic/regular';
import { makeLowerAndOnlyLetter } from '../../helperFunctions/LinkGenerator';
import { brandKeyGetByName, brandTitleGet, getSqlBrand } from '../../configurations/Brands';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import { setScreenReaderAlert } from '../../helperFunctions/Accessibility';

export function ConfigureBrand({ brand, lang }) {
  const location = useLocation();
  const [filterType, setFilterType] = useState('');
  const [filterSourceSeq, setFilterSourceSeq] = useState('');

  useEffect(() => {
    // Check if the URL matches the pattern /widget/:brand/:lang/
    const urlPattern = /^\/widget\/[\w-]+\/[\w-]+\/?$/;
    const categoryIdPattern = /^\/widget\/[\w-]+\/[\w-]+\/category\/\d+\/?$/;

    // reset filters if you move backwards from the original starting point
    if (urlPattern.test(location.pathname) || (categoryIdPattern.test(location.pathname) && filterType === 'Section')) {
      // Only set screen alert if there is a filter
      if (filterType !== '') setScreenReaderAlert(t('Widget_Removed_Filter', null, lang).replace('xBrand', brandTitleGet(brand)));
      // URL matches the pattern, reset filters to empty string
      // remove filter text and remove added margin
      if (document.getElementById('filter-searching-articles-text') !== null) {
        document.getElementById('filter-searching-articles-text').style.display = 'none';
        document.querySelector('.widget-main-content').style.marginTop = '145px';
      }
      setFilterType('');
      setFilterSourceSeq('');
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const queryFilterType = urlParams.get('filterOn');
      const queryFilterSourceSeq = urlParams.get('sourceSeq');
      if (queryFilterType && queryFilterSourceSeq) {
        document.querySelector('.widget-main-content').style.marginTop = '150px';
        if (document.getElementById('filter-searching-articles-text') !== null) {
          document.getElementById('filter-searching-articles-text').style.display = 'block';
        }

        setFilterType(queryFilterType.charAt(0).toUpperCase() + queryFilterType.slice(1));
        setFilterSourceSeq(queryFilterSourceSeq);
      }
    }
  }, [location.pathname]);
  if (filterType && filterSourceSeq) {
    // configure filters for algolia search
    return (
      <Configure
        facetFilters={[
          ['Type: E', 'Type: B'],
          ['Status: A'],
          [`Brand: ${getSqlBrand(brand)}`],
          [`${filterType}.SourceSeq: ${filterSourceSeq}`],
        ]}
        hitsPerPage={5}
      />
    );
  }
  return (
    <Configure
      facetFilters={[
        ['Type: E', 'Type: B'],
        ['Status: A'],
        [`Brand: ${getSqlBrand(brand)}`],
      ]}
      hitsPerPage={5}
    />
  );
}

const algoliaClient = algoliasearch('56TMYO60WS', process.env.REACT_APP_ALGOLIA_API_KEY);
export const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return algoliaClient.search(requests);
  },
};

export const indexNameDevelopment = process.env.REACT_APP_ALGOLIA_INDEX;

function Hits({ hits, lang, isWidget }) {
  let currentHit = 0;
  return (
    <div className={`${isWidget ? 'widget-' : ''}algolia-search-result-pulldown`}>
      {hits
        .map((hit) => {
          currentHit += 1;
          let articleExist = false;
          let articleTitle;
          // Check if lang exist for article
          hit.Content.forEach((res) => {
            if (res.Language === lang) {
              articleTitle = res.Title;
              articleExist = true;
            }
          });

          if (articleExist) {
            // Get correct section language
            let sectionTitle;
            hit.Section.forEach((res) => {
              if (res.Language === lang) {
                sectionTitle = res.Title;
              }
            });

            // Get correct category language
            let searchCategory;
            hit.Category.forEach((res) => {
              if (res.Language === lang) {
                searchCategory = res.Title;
              }
            });
            const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

            const brand = brandKeyGetByName(hit.Brand);
            const id = `${hit.objectID}${!isWidget ? `-${makeLowerAndOnlyLetter(articleTitle)}` : ''}`;
            const link = `${isWidget ? '/widget' : ''}/${brand}/${lang}/articles/${id}${isMobile}`;

            return (
              <Link
                className="no-decoration"
                id={(currentHit === hits.length) ? 'last-hit' : ''}
                to={link}
                key={hit.objectID}
                onKeyDown={(e) => {
                  if (e.target.id === 'last-hit' && e.keyCode === 9 && !e.shiftKey) {
                    $('.ais-SearchBox-reset').click();
                  }
                }}
              >
                <Card className="m-1 widget-search-result-card">
                  <span className="widget-algolia-section">
                    <span>
                      <ReplaceTextWithElement
                        text={searchCategory}
                        replaceWith={<i>Interac</i>}
                        replaceKey="Interac"
                      />
                    </span>
                    <FontAwesomeIcon className="algolia-header-separator" icon={faChevronRight} aria-label={t('IconDescription_Breadcrumb_Chevron')} />
                    <span>
                      <ReplaceTextWithElement
                        text={sectionTitle}
                        replaceWith={<i>Interac</i>}
                        replaceKey="Interac"
                      />
                    </span>
                  </span>
                  {/* search content */}
                  <ReplaceTextWithElement
                    text={articleTitle}
                    replaceWith={<i>Interac</i>}
                    replaceKey="Interac"
                  />
                </Card>
              </Link>
            );
          }
          return null;
        })}
    </div>
  );
}
export const CustomHits = connectHits(Hits);

let announceResultTimeout = setTimeout;

function Results({ searchState, searchResults, searching, lang }) {
  function announceResult(isFound) {
    clearTimeout(announceResultTimeout);
    announceResultTimeout = setTimeout(() => {
      setScreenReaderAlert(isFound ?
        t('ScreenReaderAlert_Search_Found', null, lang) :
        t('ScreenReaderAlert_Search_Not_Found', null, lang));
    }, 700);
  }

  const hasSearched = !searching && searchState.query && searchResults;
  const hasResults = hasSearched && searchResults.nbHits !== 0;
  const hasNoResults = hasSearched && searchResults.nbHits === 0;
  if (hasSearched && hasNoResults) {
    announceResult(false);
  }
  if (hasSearched && hasResults) {
    announceResult(true);
  }

  return (
    <>
    </>
  );
}

export const CustomResults = connectStateResults(Results);

export const useCollapsibleSearchResult = (isWidget = false) => {
  useEffect(() => {
    const searchResultPulldown = $(
      `.${isWidget ? 'widget-' : ''}algolia-search-result-pulldown`
    );

    $(`.${isWidget ? 'widget-' : ''}ais-InstantSearch`).focusin(() => {
      searchResultPulldown.css({ visibility: 'visible' });
    });
    searchResultPulldown.focusin(() => {
      searchResultPulldown.css({ visibility: 'visible' });
    });
    $(isWidget ? '.widget-searchBox' : '.ais-SearchBox-input').keydown(
      (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
        }
        searchResultPulldown.css({ visibility: 'visible' });
      }
    );
    $(document).click((event) => {
      if (event.target.className !== 'ais-SearchBox-input') {
        searchResultPulldown.css({ visibility: 'hidden' });
      }
    });
    return () => { $(document).off('click'); };
  }, [isWidget]);
};
