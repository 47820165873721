/* eslint-disable */

import psImgNav from "../images/paysimply/PaySimply-nav.png";
import zwImgNav from "../images/zenwallet/Zenwallet-nav.png";
import tmuImgNav from "../images/topmeup/Topmeup-nav.png";
import dpImgNav from "../images/directpay/DirectPay-nav.png";
import slpImgNav from "../images/slp/CanadaPostLogo.svg";
import nppImgNav from "../images/nowprepay/Nowprepay-nav.png";

import zwIco from "../pages/dynamicPages/favicon/Zenwallet_favicon.ico";
import psIco from "../pages/dynamicPages/favicon/Paysimply_favicon.png";
import npIco from "../pages/dynamicPages/favicon/Nowprepay_favicon.png";
import tmuIco from "../pages/dynamicPages/favicon/Topmeup_favicon.ico";
import dpIco from "../pages/dynamicPages/favicon/DirectPay_favicon.png";
import mpIco from "../pages/dynamicPages/favicon/Maple_favicon.png";
import slpIco from "../pages/dynamicPages/favicon/Slp-favicon.ico";

import paySimply from "../images/paysimply/Paysimply-color-desktop.png";
import nowPrepay from "../images/nowprepay/Nowprepay-color-desktop.png";
import topMeUp from "../images/topmeup/Topmeup-color-desktop.png";
import zenWallet from "../images/zenwallet/Zenwallet-color-desktop.png";
import directPay from "../images/directpay/DirectPay-Desktop.png";
import Slp from "../images/slp/Slp-Card.png";

import nowPrepayMobile from "../images/nowprepay/Nowprepay-color-mobile.png";
import topMeUpMobile from "../images/topmeup/Topmeup-color-mobile.png";
import paySimplyMobile from "../images/paysimply/Paysimply-color-mobile.png";
import zenwalletMobile from "../images/zenwallet/Zenwallet-color-mobile.png";
import directPayMobile from "../images/directpay/DirectPay-Mobile.png";
import mapleMobile from "../images/maple/MagMobile.png";

import npp from "../images/widget/Nowprepay-color-mobile.png";
import ps from "../images/widget/Paysimply-color-mobile.png";
import tmu from "../images/widget/Topmeup-color-mobile.png";
import zw from "../images/widget/Zenwallet-color-mobile.png";
import mapleDesktop from "../images/maple/MagstripeCard.png";

import mapleNav from "../images/maple/CanadaPostLogo.svg";

import partnersIcon from "../images/partners/PartnersFavicon.png";
import partnersLogo from "../images/partners/PartnersLogo.png";

import paymentLogo from "../images/payments/PaymentsLogo.png";

import paymentSourceLogo from "../images/paymentsourcelogo_colour-1.png";

export const brandConfig = Object.freeze({
  // PaySimply
  paysimply: {
    name: "PaySimply",
    sqlName: "PaySimply",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_PaySimply",
        text: "Navbar_Paysimply",
      },
      footer: {
        tcLink: "Link_PaySimplyTerms",
        privacyLink: "Link_PaySimplyPrivacy",
      },
    },
    footerLink: [
      ["Footer_Terms_And_Condition", "Link_PaySimplyTerms"],
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: psImgNav,
      logoAltText: "Paysimply",
      bkgColor: "#bb1b20",
      fontColor: "rgba(255, 255, 255, 1)",
      langnumber: "mr-1 langdrop1",
      navclass: "paysimplyNavClass",
    },
    genesysDeploymentKey: "",
    favicon: psIco,
    homePage: {
      link: "paysimply/en/categories",
      imageDesktop: paySimply,
      imageMobile: paySimplyMobile,
    },
    widgetMode: {
      theme: {
        backgroundColor: "#bb1b20",
        color: "rgba(255, 255, 255, 1)",
      },
      image: ps,
    },
    mobileMode: {
      endChatBtn: {
        light: "#2b4e7b",
        dark: "#a5c3ea",
      },
    },
  },

  // maple
  "canadapost-prepaid": {
    sqlName: "Maple",
    screenReader: {
      en: "Canada Post Prepaid Reloadable Visa Card",
      fr: "Carte Visa prépayée rechargeable de Postes Canada",
    },
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_MapleWeb",
        text: "Navbar_Maple_About",
      },
    },
    footerLink: [
      ["Footer_Lbl_Cardholder_Agreement", "Link_Maple_CardholderAgreement"],
      ["Footer_Maple_TermsAndService", "Link_Maple_TermsCondition"],
      ["Footer_Lbl_Peoples_Privacy_Policy", "Link_Peoples_Privacy"],
      ["Footer_Lbl_PSI_Privacy_Policy", "Link_PSI_Privacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: mapleNav,
      logoAltText: "Maple",
      bkgColor: "#ffffff",
      fontColor: "#555556",
      langnumber: "mr-1 langdrop2",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "#0f102e",
    },
    genesysDeploymentKey: "",
    favicon: mpIco,
    homePage: {
      show: true,
      link: "canadapost-prepaid/en/categories",
      imageDesktop: mapleDesktop,
      imageMobile: mapleMobile,
      imageAltTag:
        "Canada Post Prepaid Reloadable Visa Card | Carte Visa prépayée rechargeable de Postes Canada",
    },
    widgetMode: {
      theme: {
        backgroundColor: "var(--mp-navy)",
        color: "white",
      },
      image: mapleMobile,
    },
    mobileMode: {
      endChatBtn: {
        light: "#4372b8",
        dark: "#4372b8",
      },
    },
  },

  partners: {
    name: "Payment Source Partners",
    sqlName: "Partners",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "",
        text: "",
      },
      footer: {
        tcLink: "Link_PaySimplyTerms",
        privacyLink: "Link_PaySimplyPrivacy",
      },
    },
    footerLink: [
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: paymentSourceLogo,
      logoAltText: "Payment Source Partners",
      logoDisplayText: "Partners",
      logoDisplayTextColor: "#02464e",
      bkgColor: "#ffffff",
      fontColor: "#555556",
      langnumber: "mr-1 langdrop1",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "#002D33",
    },
    genesysDeploymentKey: "",
    favicon: partnersIcon,
    homePage: {
      link: "partners/en/categories",
      imageDesktop: partnersLogo,
      imageMobile: partnersLogo,
      style: { padding: "30px" },
    },
    widgetMode: {
      theme: {
        backgroundColor: "#00444d",
        color: "white",
      },
      image: partnersLogo,
    },
    mobileMode: {
      endChatBtn: {
        light: "",
        dark: "",
      },
    },
  },

  payments: {
    name: "Payment Source Payments",
    sqlName: "Payments",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "",
        text: "",
      },
      footer: {
        tcLink: "Link_PaySimplyTerms",
        privacyLink: "Link_PaySimplyPrivacy",
      },
    },
    footerLink: [
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: paymentSourceLogo,
      logoAltText: "Payment Source Partners",
      logoDisplayText: "Payments",
      logoDisplayTextColor: "#02464e",
      bkgColor: "#ffffff",
      fontColor: "#555556",
      langnumber: "mr-1 langdrop1",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "#002D33",
    },
    genesysDeploymentKey: "",
    favicon: partnersIcon,
    homePage: {
      link: "payments/en/categories",
      imageDesktop: paymentLogo,
      imageMobile: paymentLogo,
      style: { padding: "30px" },
    },
    widgetMode: {
      theme: {
        backgroundColor: "#00444d",
        color: "white",
      },
      image: paymentLogo,
    },
    mobileMode: {
      endChatBtn: {
        light: "",
        dark: "",
      },
    },
  },

  // Zenwallet
  zenwallet: {
    name: "Zenwallet",
    sqlName: "Zenwallet",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_ZenwalletMain",
        text: "Navbar_Zenwallet",
      },
    },
    footerLink: [
      ["Footer_Terms_And_Condition", "Link_TopmeupTerms"],
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: zwImgNav,
      logoAltText: "Zenwallet",
      bkgColor: "#1B1C1D",
      fontColor: "rgba(255, 255, 255, 0.644)",
      langnumber: "mr-1 langdrop4",
      navclass: "zenwalletNavClass",
    },
    genesysDeploymentKey: "",
    favicon: zwIco,
    homePage: {
      link: "zenwallet/en/categories",
      imageDesktop: zenWallet,
      imageMobile: zenwalletMobile,
    },
    widgetMode: {
      theme: {
        backgroundColor: "#5e9636",
        color: "black",
      },
      image: zw,
    },
    mobileMode: {
      endChatBtn: {
        light: "#59952e",
        dark: "#73b842",
      },
    },
  },

  // Topmeup
  topmeup: {
    name: "Topmeup",
    sqlName: "Topmeup",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_TopmeupMain",
        text: "Navbar_Topmeup",
      },
    },
    footerLink: [
      ["Footer_Terms_And_Condition", "Link_TopmeupTerms"],
      ["Footer_Privacy_Policy", "Link_TopmeupPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],

    theme: {
      logo: tmuImgNav,
      logoAltText: "Topmeup",
      bkgColor: "#212121",
      fontColor: "rgba(255, 255, 255, 0.644)",
      langnumber: "mr-1 langdrop3",
      navclass: "topmeupNavClass",
    },

    genesysDeploymentKey: "b27c6051-4d3d-43a7-ab8c-9659d09f37d6",
    favicon: tmuIco,
    homePage: {
      link: "topmeup/en/categories",
      imageDesktop: topMeUp,
      imageMobile: topMeUpMobile,
    },
    widgetMode: {
      theme: {
        backgroundColor: "#448003",
        color: "white",
      },
      image: tmu,
    },
    mobileMode: {
      endChatBtn: {
        light: "#448003",
        dark: "#63b50f",
      },
    },
  },

  // Now Prepay
  nowprepay: {
    name: "Now Prepay",
    sqlName: "Now Prepay",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_Nowprepay",
        text: "Navbar_Nowprepay",
      },
    },
    footerLink: [
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: nppImgNav,
      logoAltText: "Now Prepay",
      bkgColor: "#fffff",
      fontColor: "#000",
      langnumber: "mr-1 langdrop2",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "#f7f7f7",
      footerFontColor: "#000",
      footerBorderTop: "1px solid #ababab",
    },
    genesysDeploymentKey: "b7d26277-bf19-467a-bf83-ad9f9e209563",
    favicon: npIco,
    homePage: {
      link: "nowprepay/en/categories",
      imageDesktop: nowPrepay,
      imageMobile: nowPrepayMobile,
    },
    widgetMode: {
      theme: {
        backgroundColor: "var(--npp-orange)",
        color: "black",
      },
      image: npp,
    },
    mobileMode: {
      endChatBtn: {
        light: "#4372b8",
        dark: "var(--npp-orange)",
      },
    },
  },

  // directpay
  directpay: {
    name: "DirectPay",
    sqlName: "DirectPay",
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_DirectPay",
        text: "Navbar_DirectPay",
      },
    },
    footerLink: [
      ["Footer_Terms_And_Condition", "Link_DirectPay_Terms"],
      ["Footer_Privacy_Policy", "Link_PaySimplyPrivacy"],
      ["Footer_Accessibility", "Link_AccessibilityStatement"],
    ],
    theme: {
      logo: dpImgNav,
      logoAltText: "Direct Pay",
      bkgColor: "#ffffff",
      fontColor: "#555556",
      langnumber: "mr-1 langdrop2",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "var(--dp-footer)",
    },
    genesysDeploymentKey: "",
    favicon: dpIco,
    homePage: {
      show: false,
      link: "directpay/en/categories",
      imageDesktop: directPay,
      imageMobile: directPayMobile,
    },
    widgetMode: {
      theme: {
        backgroundColor: "var(--dp-widget-header)",
        color: "var(--dp-widget-header-text)",
      },
      image: directPayMobile,
    },
    mobileMode: {
      endChatBtn: {
        light: "#4372b8",
        dark: "var(--dp-green)",
      },
    },
  },

  // SLP
  "canadapost-singleloadprepaid": {
    name: "SLP",
    sqlName: "SLP",
    screenReader: {
      en: "Canada Post Single Load Prepaid Card",
      fr: "Carte prépayée non rechargeable de Postes Canada",
    },
    languages: ["en", "fr"],
    text: {
      navBar: {
        link: "Link_SLP_Contact_Page",
        text: "Navbar_SLP_About",
      },
    },
    footerLink: [
      ["Footer_Lbl_Cardholder_Agreement", "Link_SLP_Cardholder_Agreement"],
      ["Footer_Privacy_Policy", "Link_Peoples_Privacy"],
    ],
    theme: {
      logo: slpImgNav,
      logoAltText: "SLP Card",
      bkgColor: "#ffffff",
      fontColor: "#555556",
      langnumber: "mr-1 langdrop2",
      navclass: "nowprepayNavClass",
      footerBackgroundColor: "#0f102e",
    },
    genesysDeploymentKey: "",
    favicon: slpIco,
    homePage: {
      show: false,
      link: "canadapost-singleloadprepaid/en/categories",
      imageDesktop: Slp,
      imageMobile: Slp,
    },
    widgetMode: {
      show: false,
      theme: {
        backgroundColor: "var(--mp-navy)",
        color: "white",
      },
      image: Slp,
    },
    mobileMode: {
      endChatBtn: {
        light: "#4372b8",
        dark: "var(--dp-green)",
      },
    },
  },
});
// eslint-disable-next-line no-prototype-builtins
export const checkBrandExists = (brand) => brandConfig.hasOwnProperty(brand);

export const checkBrandLangExists = (brand, lang) =>
  brandConfig[brand]?.languages.includes(lang) ?? false;

export const brandFaviconGet = (brand) => brandConfig[brand]?.favicon;

export const brandTitleGet = (brand, lang = "en") =>
  brandConfig[brand]?.screenReader?.[lang] ??
  brandConfig[brand]?.sqlName ??
  "Default";

export const getSqlBrand = (brand) => brandConfig[brand].sqlName;

export function brandKeyGetByName(name) {
  // eslint-disable-next-line no-restricted-syntax
  for (const brand in brandConfig) {
    if (brandConfig[brand].sqlName === name) return brand;
  }
  return null;
}

export const getAllBrands = () => Object.keys(brandConfig);

export const checkTypeExist = (type) => {
  return helpType.includes(type);
};

export const helpType = [
  "categories",
  "sections",
  "articles",
  "contactus",
  "category",
  "section",
  "article",
];

export const languageText = Object.freeze({
  en: "English",
  fr: "Français",
  zh: "中文",
  ko: "한국어",
});

/*If widget > show does not exist for brand, then true */
export const showWidget = (brand) =>
  brandConfig[brand]?.widgetMode?.show ?? true;

export const showOnHomePage = (brand) =>
  brandConfig[brand]?.homePage?.show ?? true;

export const getFooterLinkColor = (brand) =>
  brandConfig[brand]?.theme.footerFontColor ?? "#e8e8e8";

export const getFooterBorderTop = (brand) =>
  brandConfig[brand]?.theme.footerBorderTop ?? "";

export const getAccessibilityName = (brand) =>
  brandConfig[brand]?.homePage.imageAltTag ?? brandConfig[brand].name;

export const getFooterBackgroundColor = (brand) =>
  brandConfig[brand]?.theme.footerBackgroundColor ?? "#222222";

export const getHomePageLogoStyle = (brand) =>
  brandConfig[brand]?.homePage.style ?? null;

export const getLogoDisplayText = (brand) =>
  brandConfig[brand]?.theme.logoDisplayText ?? "";

export const getFontColor = (brand) =>
  brandConfig[brand]?.theme.fontColor ?? "black";

export const getLogoDisplayTextColor = (brand) =>
  brandConfig[brand]?.theme.logoDisplayTextColor ?? getFontColor(brand);
