import React, { Suspense, useEffect } from 'react';
import { t } from 'react-switch-lang';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Card, CardDeck } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { DbConnect } from '../../helperFunctions/DbConnect';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { brandTitleGet } from '../../configurations/Brands';
import { logAmpEvent, event } from '../../helperFunctions/Amplitude';
import Banner from '../../components/Banner/Banner';
import { makeLowerAndOnlyLetter } from '../../helperFunctions/LinkGenerator';
import './Categories.scss';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
// import { setScreenReaderAlert } from '../../helperFunctions/Accessibility';
import LoadingSpinner from '../../components/widget/widgetHelper/LoadingSpinner';
import NotFound from './NotFound';

const LightIcon = React.lazy(() => import('./LightIcon'));
const BrandsIcon = React.lazy(() => import('./BrandsIcon'));
const CustomIcon = React.lazy(() => import('./CustomIcon'));

function kebabToCamel(str) {
  return str.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
}

export function Categories2({ categories, loading, error }) {
  const { brand, lang } = useSelector((state) => state);
  const history = useHistory();

  DocumentTitle(t('PageTitle_Brand_Categories').replace('xBrand', brandTitleGet(brand, lang)));

  useEffect(() => {
    // eslint-disable-next-line max-len
    // setScreenReaderAlert(t('ScreenReaderAlert_On_Categories').replace('xBrand', brandTitleGet(brand)));
    logAmpEvent(event.USER_VIEWED_CATEGORIES_SCREEN);
  }, []);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  useEffect(() => {
    if (brand === 'directpay') {
      history.replace(`/partners/${lang}/categories`);
    }
  }, [loading, brand, lang, history]);

  function cardBox(id, icon, title, description) {
    const iconArr = icon.split(' ');
    let DynamicIcon;
    let iconName;
    if (iconArr.length === 1) {
      DynamicIcon = LightIcon;
      iconName = kebabToCamel(iconArr[0]);
    } else {
      const [style, name] = iconArr;
      iconName = kebabToCamel(name);
      switch (style) {
        case 'fa-kit':
          DynamicIcon = CustomIcon;
          break;
        case 'fa-brands':
          DynamicIcon = BrandsIcon;
          break;
        default:
          DynamicIcon = LightIcon;
      }
    }

    return (
      <Card
        key={title}
        className="card-style mx-auto"
        onClick={() => {
          logAmpEvent(event.USER_CLICKED_CATEGORY, { 'Category ID': id });
        }}
      >
        <Link
          key={id}
          className="link-to-category"
          to={`/${brand}/${lang}/categories/${id}-${makeLowerAndOnlyLetter(title)}`}
        >
          <div className="card-text-general">
            <Card.Body className="categories-card-body">
              {/* styling for fontawesome */}
              <Suspense fallback>
                <div className="mobile-icon">
                  <DynamicIcon iconName={iconName} size="2x" />
                </div>
                <div className="desktop-icon">
                  <DynamicIcon iconName={iconName} size="3x" />
                </div>
              </Suspense>

              <Card.Title className="title-card" id="category-card-title">
                <h2 className="h5">
                  <ReplaceTextWithElement
                    text={title}
                    replaceWith={<i>Interac</i>}
                    replaceKey="Interac"
                  />
                </h2>
              </Card.Title>
              <Card.Text className="card-desc">
                <ReplaceTextWithElement
                  text={description}
                  replaceWith={<i>Interac</i>}
                  replaceKey="Interac"
                />
              </Card.Text>
            </Card.Body>
          </div>
        </Link>
      </Card>
    );
  }

  function populate() {
    const categoryList = categories.Categories;
    const returnList = [];
    categoryList.forEach((item) => {
      if (item.Status === 'A') {
        returnList.push(cardBox(item.CategoryID, item.Icon, item.Title, item.Description));
      }
    });
    return returnList;
  }

  if (error) return <Redirect to={`/${brand}/${lang}/error`} />;
  if (loading) {
    return (
      <>

        <Banner lang={lang} />

        <LoadingSpinner />
      </>
    );
  }

  try {
    return (
      <section className="CategoriesPaySimply">
        <Banner lang={lang} />
        <div id="skip-link-content">
          <CardDeck className="mainDeck1 ">{populate()}</CardDeck>
        </div>
      </section>
    );
  } catch (e) {
    return (
      <NotFound brand={brand} />
    );
  }
}

export default DbConnect(Categories2, 'categories');
