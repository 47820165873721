import React from 'react';
import { t, translate } from 'react-switch-lang';
import './Feature.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-unresolved
import { faMoneyBill, faPaperPlane, faListUl, faExchange, faUndo } from '@awesome.me/kit-299dd46f46/icons/classic/light';

function Features() {
  return (
    <div className="container text-center feature-grid my-5" id="skip-to-feature">
      <h2 className="mx-3 mb-3">
        {t('MyeCashFeaturesTitle')}
      </h2>
      <div className="row feature-row">
        <div className="col-md-4">
          <FontAwesomeIcon icon={faMoneyBill} size="3x" className="m-2" />
          <h3>
            <span>{t('MyeCashFeaturesBalanceTitle')}</span>
          </h3>
          <p>{t('MyeCashFeaturesBalanceBody')}</p>
        </div>
        <div className="col-md-4">
          <FontAwesomeIcon icon={faPaperPlane} size="3x" className="m-2" />
          <h3>
            <span>{t('MyeCashFeatureseTransferTitle')}</span>
          </h3>
          <p>{t('MyeCashFeatureseTransferBody')}</p>
        </div>
        <div className="col-md-4">
          <FontAwesomeIcon icon={faListUl} size="3x" className="m-2" />
          <h3>
            <span>{t('MyeCashFeaturesActivityTitle')}</span>
          </h3>
          <p>{t('MyeCashFeaturesActivityBody')}</p>
        </div>
      </div>
      <div className="row feature-row justify-content-center">
        <div className="col-md-4">
          <FontAwesomeIcon icon={faExchange} size="3x" className="m-2" />
          <h3>
            <span>{t('MyeCashFeaturesTransferTitle')}</span>
          </h3>
          <p>{t('MyeCashFeaturesTransferBody')}</p>
        </div>
        <div className="col-md-4">
          <FontAwesomeIcon icon={faUndo} size="3x" className="m-2" />
          <h3>
            <span>{t('MyeCashFeaturesRecallTitle')}</span>
          </h3>
          <p>{t('MyeCashFeaturesBalanceBody')}</p>
        </div>
      </div>
    </div>
  );
}

export default translate(Features);
