/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import './contactusform.scss';
import './ContactUsPaySimply.scss';
import { useSelector } from 'react-redux';
import { SubmitContactUs } from '../../../helperFunctions/SubmitContactUs';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';
import ReplaceTextWithElement from '../../../helperFunctions/ReplaceTextWithElement';

function ContactUsPayments({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);
  const emailRef = useRef();
  const bodyRef = useRef();
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [disable, setDisable] = useState(false);

  const [validateEmailOnChange, setValidateEmailOnChange] = useState(false);
  const [validateDescriptionOnChange, setValidateDescriptionOnChange] = useState(false);
  const [validateBusinessOnChange, setValidateBusinessOnChange] = useState(false);
  const [validatePaymentMethodOnChange, setValidatePaymentMethodOnChange] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  const [formError, setFormError] = useState({
    email: null,
    businessName: null,
    description: null,
    paymentMethod: null,
  });
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  function sendAPI() {
    const data = {
      BrandID: 'Payments',
      CustomerEmail: emailRef.current.value,
      Body: bodyRef.current.value,
      PaymentMethod: paymentMethod,
      Language: lang,
    };

    const sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShowError(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShowError(true);
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function validateEmail(value) {
    if (value.length > 50) return t('Validation_Length_Max').replace('xMax', '50');
    if (!/^[\w!#$%&*+/=?`{|}~^-‘’']+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/.test(value)) return t('Validation_Type_Email');
    return null;
  }

  function validateEmpty(value) {
    if (value.trim().length === 0) return t('Validation_Required');
    return null;
  }

  function validateMaxCharacter(value, characterLimit) {
    if (value.trim().length > characterLimit) return t('ContactForm_Max_50');
    return null;
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  function validateFormFields(force) {
    const errors = {
      email: null,
      paymentMethod: null,
      description: null,
    };
    if ((validateEmailOnChange || force) && emailRef.current) {
      const { value } = emailRef.current;
      errors.email = validateEmpty(value) ||
      validateEmail(value) ||
      validateMaxCharacter(value, 100);
    }
    if ((validateDescriptionOnChange || force) && bodyRef.current) {
      const { value } = bodyRef.current;
      errors.description = validateEmpty(value) || validateMaxCharacter(value, 1500);
    }

    if ((validatePaymentMethodOnChange || force) && paymentMethod === '') {
      errors.paymentMethod = t('Validation_Required');
    }

    setFormError(errors);
    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setValidateDescriptionOnChange(true);
    setValidateEmailOnChange(true);
    setValidateBusinessOnChange(true);
    setValidatePaymentMethodOnChange(true);

    const errors = validateFormFields(true);
    if (!Object.values(errors).some((err) => err !== null)) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
  }, []);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  useEffect(() => {
    validateFormFields();
  }, [
    lang,
    validateBusinessOnChange,
    validateEmailOnChange,
    validateDescriptionOnChange,
    validatePaymentMethodOnChange,
    paymentMethod]);

  return (
    <>
      {showError && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShowError(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/${brand}/${lang}${isMobile}` : `/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">{t('ContactForm_Fields_Required')}</p>
          <Form noValidate onSubmit={handleSubmit}>

            {/* Email */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <Form.Label as="legend" id="aria-email" className="form-labels">
                {t('ContactForm_General_Email_Label')}
              </Form.Label>
              <Form.Text className="muted email-help">
                {t('ContactForm_General_Email_Help')}
              </Form.Text>
              <Form.Control
                aria-labelledby="aria-email aria-email-warn"
                ref={emailRef}
                className={formError.email ? 'is-invalid' : ''}
                onBlur={() => setValidateEmailOnChange(true)}
                onChange={() => {
                  if (validateEmailOnChange) validateFormFields();
                }}
              />
              <Form.Text className="errorMessage">{formError.email}</Form.Text>
            </Form.Group>

            {/* Which payment option did you use */}
            <Form.Group
              className="form-inline"
              controlId="ContactForm_AppID_"
              required
            >
              <fieldset>
                <Form.Label id="app_label" as="legend" className="form-labels">
                  {t('ContactForm_Which_Payment_Option')}
                </Form.Label>
                <div className="button-group">
                  <Form.Check
                    type="radio"
                    id="eTransfer"
                    name="paymentMethod"
                    value="eTransfer"
                    checked={paymentMethod === 'eTransfer'}
                    onChange={() => {
                      setValidatePaymentMethodOnChange(true);
                      setPaymentMethod('eTransfer');
                    }}
                    className="btn-radio"
                  />
                  <label
                    htmlFor="eTransfer"
                    className={`mb-2 paymentButton clickable-btn ${paymentMethod === 'eTransfer' ? 'active-btn' : 'inactive-btn'}`}
                    tabIndex="0"
                    onBlur={() => setValidatePaymentMethodOnChange(true)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        setValidatePaymentMethodOnChange(true);
                        setPaymentMethod('eTransfer');
                      }
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ReplaceTextWithElement
                      text={t('ContactForm_PaymentOption_eTransfer')}
                      replaceWith={<i>Interac</i>}
                      replaceKey="Interac"
                    />

                  </label>

                  <Form.Check
                    type="radio"
                    id="inPerson"
                    name="paymentMethod"
                    value="inPerson"
                    checked={paymentMethod === 'In Person at Canada Post'}
                    onChange={() => {
                      setValidatePaymentMethodOnChange(true);
                      setPaymentMethod('In Person at Canada Post');
                    }}
                    className="btn-radio"
                  />
                  <label
                    htmlFor="inPerson"
                    className={`paymentButton clickable-btn ${paymentMethod === 'In Person at Canada Post' ? 'active-btn' : 'inactive-btn'}`}
                    tabIndex="0"
                    onBlur={() => setValidatePaymentMethodOnChange(true)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        setValidatePaymentMethodOnChange(true);
                        setPaymentMethod('In Person at Canada Post');
                      }
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {t('In-Person_at_Canada_Post')}
                  </label>
                </div>
                <Form.Text className="errorMessage">
                  {formError.paymentMethod}
                </Form.Text>
              </fieldset>
            </Form.Group>

            {/* How can we help */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Body_"
              required
            >
              <fieldset>
                <Form.Label as="legend" id="aria-desc" className="form-labels">
                  {t('ContactForm_General_Body_Label')}
                </Form.Label>

                <Form.Control
                  aria-labelledby="aria-desc"
                  as="textarea"
                  rows={8}
                  ref={bodyRef}
                  className={formError.description ? 'is-invalid' : ''}
                  onBlur={() => setValidateDescriptionOnChange(true)}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (validateDescriptionOnChange) validateFormFields();
                  }}
                />
                <Form.Text className="errorMessage">{formError.description}</Form.Text>
              </fieldset>
            </Form.Group>
            <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />
            <Button
              id="submit-button"
              className="paysimply-button"
              type="submit"
              disabled={disable}
              size="lg"
              style={{ backgroundColor: '#2B4E7B' }}
            >
              {t('ContactForm_Submit_button')}
            </Button>

          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsPayments);
