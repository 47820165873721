import React from 'react';
import './Home.scss';
import { Link } from 'react-router-dom';
import paymentSource from '../../images/paymentsourcelogo_colour-1.png';
import { brandConfig, getAccessibilityName, getHomePageLogoStyle, showOnHomePage } from '../../configurations/Brands';
import { logAmpEvent, event } from '../../helperFunctions/Amplitude';
import { isDevelopment } from '../../helperFunctions/config';

// IMAGE RATIOS ARE 1.67

function Home() {
  function renderDesktopBrands(hiddenBrand) {
    const keys = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in brandConfig) {
      if (showOnHomePage(key) && !hiddenBrand) {
        keys.push(key);
      } else if (!showOnHomePage(key) && hiddenBrand) {
        keys.push(key);
      }
    }

    const slitBrands = keys.reduce((rows, key, index) => (
      (index % 2 === 0 ?
        rows.push([key]) :
        rows[rows.length - 1].push(key)) && rows
    ), []);

    return slitBrands.map((row) => (
      <tr key={row}>
        {row.map((key) => (
          <td key={key} className="home-table">
            <div className="home-card-style mx-auto card">
              <Link
                className="brand-card-body"
                to={brandConfig[key].homePage.link}
                onClick={() => {
                  logAmpEvent(event.USER_CLICKED_HOME_PAGE, { brand: key });
                }}
                title={getAccessibilityName(key)}
              >
                <img
                  className="card-img"
                  src={brandConfig[key].homePage.imageDesktop}
                  alt={getAccessibilityName(key)}
                  style={getHomePageLogoStyle(key)}
                />
              </Link>
            </div>
          </td>
        ))}
      </tr>
    ));
  }

  function renderMobileBrands() {
    const keys = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in brandConfig) {
      keys.push(key);
    }

    return keys.map((key) => {
      if (showOnHomePage(key) === false) {
        return null;
      }
      return (
        <div key={key} className="home-card-style mx-auto card">
          <Link
            className="card-body"
            to={brandConfig[key].homePage.link}
            onClick={() => {
              logAmpEvent(event.USER_CLICKED_HOME_PAGE, { brand: key });
            }}
          >
            <img
              className="card-img"
              src={brandConfig[key].homePage.imageMobile}
              alt={brandConfig[key].name}
            />
          </Link>
        </div>
      );
    });
  }

  function returnHiddenBrandsDebug() {
    if (isDevelopment()) {
      return (
        <>
          <hr />
          <p className="widget-test-title">These are hidden brands only shown on test</p>
          <table id="home-debug-table">
            <div id="debug-hidden-brands">
              {renderDesktopBrands(true)}
            </div>
          </table>
        </>
      );
    }
    return null;
  }

  function createWidget(e) {
    let link;
    // eslint-disable-next-line default-case
    switch (e.currentTarget.getAttribute('data-value')) {
      case 'regular':
        link = document.getElementById('create-regular-widget-textbox').value;
        break;
      case 'category':
        link = document.getElementById('create-category-widget-textbox').value;
        break;
      case 'section':
        link = document.getElementById('create-section-widget-textbox').value;
        break;
      case 'mobileapp':
        link = document.getElementById('create-mobileapp-widget-textbox').value;
        break;
    }

    const widgetScript = document.createElement('script');
    widgetScript.setAttribute('src', `${window.location.origin}/WidgetJS.js`);
    widgetScript.setAttribute('link', link);
    document.body.appendChild(widgetScript);

    // hide debug menu
    document.getElementById('widget-debug-menu').style.display = 'none';
  }

  function showWidgetDebug() {
    if (isDevelopment()) {
      return (
        <>
          <hr />
          <p className="widget-test-title">Widget Test Mode</p>
          <p>Please refresh this page to remove widget button.</p>
          <div className="debug-widget">
            <input
              className="widget-debug-input"
              id="create-regular-widget-textbox"
              defaultValue={`${window.location.origin}/widget`}
            />
            <button id="create-widget-button" type="button" data-value="regular" onClick={createWidget}>Regular</button>
          </div>
          <div className="debug-widget">
            <input
              className="widget-debug-input"
              id="create-category-widget-textbox"
              defaultValue={`${window.location.origin}/widget/paysimply/en/category/825`}
            />
            <button id="create-widget-button" type="button" data-value="category" onClick={createWidget}>
              Category
            </button>
          </div>
          <div className="debug-widget">
            <input
              className="widget-debug-input"
              id="create-section-widget-textbox"
              defaultValue={`${window.location.origin}/widget/paysimply/en/section/2571`}
            />
            <button id="create-widget-button" type="button" data-value="section" onClick={createWidget}>Section</button>
          </div>
          <div className="debug-widget">
            <input
              className="widget-debug-input"
              id="create-mobileapp-widget-textbox"
              defaultValue={`${window.location.origin}/widget/paysimply/en/section/2571?mobileapp`}
            />
            <button id="create-widget-button" type="button" data-value="mobileapp" onClick={createWidget}>Mobile App</button>
          </div>
        </>
      );
    }
    return null;
  }

  return (
    <div id="main-content" className="Home home-page">
      <div className="logo-box">
        <img className="paymentsource-image" src={paymentSource} alt="Payment Source" />
      </div>
      <div>
        <h1 id="help-text">What do you need help with?</h1>
      </div>
      <div id="home-mobile">{renderMobileBrands()}</div>

      <div id="home-desktop">
        <table id="desktop-table" className="center" role="presentation">
          <tbody>{renderDesktopBrands(false)}</tbody>
        </table>
      </div>
      {returnHiddenBrandsDebug()}
      <div id="widget-debug-menu">
        {showWidgetDebug()}
      </div>
    </div>
  );
}

export default Home;
